import { httpClient } from "@common/Libs/axios";
import {
  CommonNewsFiltersType,
  NewsDetailResponse,
  NewsDetailType,
  NewsEditFormType,
  NewsFormType,
  NewsMutateFormType,
} from "@common/Types";
import { buildCommonFiltersValues, commaSerializer } from "@common/Utils";

import { newsPath } from "./paths";

/**
 * Описание методов news.
 */

export const newsService = {
  getNews: (filters?: CommonNewsFiltersType, skip?: number, limit?: number) =>
    httpClient.get<NewsDetailResponse>(newsPath.news, {
      params: { skip, limit, ...buildCommonFiltersValues(filters) },
      paramsSerializer: commaSerializer,
    }),

  createNews: (data: NewsFormType) =>
    httpClient.post<NewsFormType, NewsFormType>(newsPath.news, data),

  getNewsDetail: ({ id, type }: NewsMutateFormType) =>
    httpClient.get<NewsDetailType>(newsPath.newsDetail(id), {
      params: { type },
    }),

  editNews: ({ data, type }: NewsEditFormType) =>
    httpClient.patch<NewsFormType, NewsFormType>(
      newsPath.newsDetail(data.id),
      data,
      { params: { type } },
    ),

  deleteNews: ({ id, type }: NewsMutateFormType) =>
    httpClient.delete(newsPath.newsDetail(id), {
      params: { type },
    }),
};
