import {
  Radio as MantineRadio,
  RadioGroup as MantineRadioGroup,
} from "@mantine/core";
import { Field, useField } from "formik";
import React from "react";

import { Typography } from "@components/Elements";

import { RadioGroupProps } from "./types";
import { RadioGroupClasses, RadioGroupRadioClasses } from "./variants";

// Imported Radio from Mantine because RadioGroup only supports Mantine components.

export const RadioGroup = ({
  view = "brand",
  size = "sm",
  data,
  label,
  ...rest
}: RadioGroupProps) => {
  return (
    <MantineRadioGroup
      label={
        <Typography
          color="neutral-800"
          variant="caption-b"
          className="font-medium"
        >
          {label}
        </Typography>
      }
      classNames={RadioGroupClasses[view]}
      size={size}
      {...rest}
    >
      {data.map(({ label, value }, index) => (
        <MantineRadio
          key={`${value}-${index}`}
          classNames={RadioGroupRadioClasses[view]}
          label={label}
          value={value}
        />
      ))}
    </MantineRadioGroup>
  );
};

export const FormRadioGroup = ({
  field,
  ...rest
}: RadioGroupProps & { field: string }) => {
  const [fieldProps, { touched, error }, { setValue }] = useField(field);

  return (
    <Field
      as={RadioGroup}
      error={touched && error}
      {...fieldProps}
      {...rest}
      onChange={setValue}
    />
  );
};
