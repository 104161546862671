import React from "react";

import { backgroundColorClasses } from "@common/Constants";
import { ColorClassKey } from "@common/Types";
import { cn } from "@common/Utils";

import { Row } from "../flexbox";
import { Typography, TypographyProps } from "../typography";

type Props = {
  color: ColorClassKey | string;
  label: string;
  value?: string | number;
  labelClass?: Omit<TypographyProps, "children">;
  valueClass?: Omit<TypographyProps, "children">;
  className?: string;
};

export const BadgeLegend = ({
  color,
  label,
  value,
  labelClass,
  valueClass,
  className,
}: Props) => {
  const isClassKey = typeof color !== "string";

  return (
    <Row alignItems="items-center" className={cn("space-x-1", className)}>
      <div>
        <div
          className={cn(
            "w-2 h-2 rounded-full",
            isClassKey && backgroundColorClasses[color],
          )}
          style={isClassKey ? undefined : { backgroundColor: color }}
        />
      </div>

      <Row
        alignItems="items-center"
        justifyContent="justify-between"
        className="w-full gap-4"
      >
        <Typography variant="caption-a" color="neutral-600" {...labelClass}>
          {label}
        </Typography>

        {value && (
          <Typography
            color="neutral-800"
            className="font-medium text-right"
            {...valueClass}
          >
            {value}
          </Typography>
        )}
      </Row>
    </Row>
  );
};
