import React from "react";

export const ImageUploadIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6667 8.66659V13.6499C26.6667 13.6499 23.35 13.6666 23.3333 13.6499V8.66659H18.3333C18.3333 8.66659 18.35 5.34992 18.3333 5.33325H23.3333V0.333252H26.6667V5.33325H31.6667V8.66659H26.6667ZM21.6667 15.3333V10.3333H16.6667V5.33325H3.33333C1.5 5.33325 0 6.83325 0 8.66659V28.6666C0 30.4999 1.5 31.9999 3.33333 31.9999H23.3333C25.1667 31.9999 26.6667 30.4999 26.6667 28.6666V15.3333H21.6667ZM3.33333 28.6666L8.33333 21.9999L11.6667 26.9999L16.6667 20.3333L23.3333 28.6666H3.33333Z"
        fill="#AEB1BB"
      />
    </svg>
  );
};

export const ImageUploadIconV2 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33333 2.66667C3.86057 2.66667 2.66667 3.86057 2.66667 5.33333V12.7811L5.72386 9.72388L5.74217 9.70591C6.54533 8.93307 7.55226 8.44019 8.66667 8.44019C9.78108 8.44019 10.788 8.93307 11.5912 9.70591L11.6095 9.72388L14.6667 12.7811L15.0572 12.3905L15.0755 12.3726C15.8787 11.5997 16.8856 11.1069 18 11.1069C19.1144 11.1069 20.1213 11.5997 20.9245 12.3726L20.9428 12.3905L21.3333 12.7811V5.33333C21.3333 3.86057 20.1394 2.66667 18.6667 2.66667H5.33333ZM24 15.9987V5.33333C24 2.38781 21.6122 0 18.6667 0H5.33333C2.38781 0 0 2.38781 0 5.33333V15.9998C0 15.9996 0 15.9999 0 15.9998V18.6667C0 21.6122 2.38781 24 5.33333 24H18.6667C21.6122 24 24 21.6122 24 18.6667V16.0013C24 16.0005 24 15.9996 24 15.9987ZM21.3333 16.5523L19.0671 14.2861C18.6575 13.8951 18.2879 13.7735 18 13.7735C17.7121 13.7735 17.3425 13.8951 16.9329 14.2861L16.5523 14.6667L18.2761 16.3905C18.7968 16.9112 18.7968 17.7555 18.2761 18.2762C17.7554 18.7969 16.9112 18.7969 16.3905 18.2762L9.73379 11.6194C9.32414 11.2284 8.95459 11.1069 8.66667 11.1069C8.37874 11.1069 8.00919 11.2284 7.59954 11.6194L2.66667 16.5523V18.6667C2.66667 20.1394 3.86057 21.3333 5.33333 21.3333H18.6667C20.1394 21.3333 21.3333 20.1394 21.3333 18.6667V16.5523ZM14.6667 6.66667C14.6667 5.93029 15.2636 5.33333 16 5.33333H16.0133C16.7497 5.33333 17.3467 5.93029 17.3467 6.66667C17.3467 7.40305 16.7497 8 16.0133 8H16C15.2636 8 14.6667 7.40305 14.6667 6.66667Z"
        fill="#333333"
      />
    </svg>
  );
};
