import {
  DateRageFilterType,
  IDType,
  SubscriberFilterType,
} from "@common/Types";

export enum NewsEnum {
  news_mobile = "apps",
  news_web = "landing",
}

export type NewsFiltersType = {
  date_range: DateRageFilterType;
  type: NewsEnum;
  subscribers?: SubscriberFilterType;
  language?: string;
  city_id?: string;
};

export type CommonNewsFiltersType = NewsFiltersType;

export type NewsDetailType = {
  id: number;
  subscribers: string;
  title: string;
  image_url?: string;
  text?: string;
  created_at?: string;
  language: string;
  city_id?: number;
  show_on_main?: boolean;
  background_color: string;
};

export type NewsDetailResponse = {
  length: number;
  items: NewsDetailType[];
};

export type NewsRequestParams = {
  limit?: number;
  skip?: number;
  filters?: CommonNewsFiltersType;
};

export type NewsFormType = {
  title: string;
  text: string;
  language?: string;
  image_id?: number;
  subscribers?: string;
  type: NewsEnum;
  show_on_main: boolean;
  background_color: string;
  city_id?: number;
  id?: number;
  image_url?: string;
};

export type NewsMutateFormType = {
  id: IDType;
  type: NewsEnum;
};

export type NewsEditFormType = {
  data: NewsFormType;
  type: NewsEnum;
};
