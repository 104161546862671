import { ModalProps } from "@mantine/core";
import React, { createContext, ReactNode, useContext, useState } from "react";

import { ModalMap, ModalMapMetaKeys, ModalMetaMap } from "@common/Types";
import { ModalLazyComponent } from "@components/Modals/modal-lazy-component";

type ModalMeta<T extends ModalMapMetaKeys> = ModalMetaMap[T] &
  Omit<ModalProps, "opened" | "onClose">;

type ModalProviderProps = {
  children: ReactNode;
};

type ModalContextProps = {
  openModal: <T extends ModalMapMetaKeys>(id: T, meta: ModalMeta<T>) => void;
  closeModal: <T extends ModalMapMetaKeys>(id: T) => void;
  getModalMeta: (
    id: ModalMapMetaKeys,
  ) => ModalMetaMap[ModalMapMetaKeys] | undefined;
  isModalOpen: (id: ModalMapMetaKeys) => boolean;
};

type ModalInitalState = {
  modal: ModalMap;
};

const initialState: ModalInitalState = {
  modal: {},
};

export const ModalContext = createContext<ModalContextProps | null>(null);

export const useModal = (): ModalContextProps => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModalContext must be used within a ModalProvider");
  }

  return context;
};

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modalState, setModalState] = useState(initialState);

  const openModal = <T extends ModalMapMetaKeys>(id: T, meta: ModalMeta<T>) => {
    setModalState((prev) => ({
      ...prev,
      modal: {
        ...prev.modal,
        [id]: { id, open: true, meta },
      },
    }));
  };

  const closeModal = <T extends ModalMapMetaKeys>(id: T) => {
    setModalState((prev) => ({
      ...prev,
      modal: {
        ...prev.modal,
        [id]: { id, open: false },
      },
    }));
  };

  const getModalsList = Object.keys(modalState.modal).filter(
    (id) => modalState.modal[id].open,
  );

  const isModalOpen = (id: ModalMapMetaKeys): boolean =>
    modalState.modal[id]?.open ?? false;

  const getModalMeta = (id: ModalMapMetaKeys) => modalState.modal[id]?.meta;

  return (
    <ModalContext.Provider
      value={{ openModal, closeModal, isModalOpen, getModalMeta }}
    >
      {getModalsList.map((filename) => (
        <ModalLazyComponent
          key={filename}
          filename={filename as ModalMapMetaKeys}
        />
      ))}

      {children}
    </ModalContext.Provider>
  );
};
