import * as Sentry from "@sentry/react";
import axios from "axios";
import React, { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const appEnv = process.env.REACT_APP_ENV as "staging" | "dev" | "prod";
const appTarget = process.env.REACT_APP_BUILD_TARGET as keyof typeof configs;
const appVersion = "1.1.16";

const configs = {
  motor: {
    dev: {
      dns: "https://1feded94edb5835ff9923e1701fee808@o4506217922035712.ingest.us.sentry.io/4508872502083584",
      tracePropagationTargets: [
        "dev.motortaxi.kz",
        /^https:\/\/dev\.motortaxi\.kz\/api/,
      ],
    },
    staging: {
      dns: "https://1feded94edb5835ff9923e1701fee808@o4506217922035712.ingest.us.sentry.io/4508872502083584",
      tracePropagationTargets: [
        "staging.motortaxi.kz",
        /^https:\/\/staging\.motortaxi\.kz\/api/,
      ],
    },
    prod: {
      dns: "https://992d577c844697358b2f641ed25cc9b1@o4506217922035712.ingest.us.sentry.io/4508872504705024",
      tracePropagationTargets: [
        "motortaxi.kz",
        /^https:\/\/motortaxi\.kz\/api/,
      ],
    },
  },
  navi: {
    dev: {
      dns: "https://9b238e033e504fc607c951621a0d6307@o4508381086547968.ingest.us.sentry.io/4508872497233920",
      tracePropagationTargets: ["dev.navi.kg", /^https:\/\/dev\.navi\.kg\/api/],
    },
    staging: {
      dns: "https://9b238e033e504fc607c951621a0d6307@o4508381086547968.ingest.us.sentry.io/4508872497233920",
      tracePropagationTargets: [
        "union.staging.navi.kg",
        /^https:\/\/union\.staging\.navi\.kg\/api/,
      ],
    },
    prod: {
      dns: "https://7c8640476ffc4ea3d3df2dcda6a866d4@o4508381086547968.ingest.us.sentry.io/4508872499658752",
      tracePropagationTargets: [
        "union.navi.kg",
        /^https:\/\/union\.navi\.kg\/api/,
      ],
    },
  },
};

const sentryConfig = configs[appTarget]?.[appEnv];

if (sentryConfig) {
  Sentry.init({
    dsn: sentryConfig.dns,
    enabled: process.env.NODE_ENV !== "development",
    debug: process.env.NODE_ENV === "development",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracePropagationTargets: sentryConfig.tracePropagationTargets,
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 0.1, // 10% CPU-профайлинга
    environment: appEnv,
    release: `${appTarget}@${appVersion}`,

    beforeSendTransaction(event) {
      if (
        event.transaction &&
        (event.transaction.startsWith("slow_ui") ||
          event.transaction.includes("webpack"))
      ) {
        return null; // Не отправлять профили UI, если их слишком много
      }

      return event;
    },

    denyUrls: [
      //Ошибки, вызванные расширениями браузера (не зависят от кода)
      /extensions\//i, // Chrome extensions
      /^chrome:\/\//i, // Chrome внутренние ошибки
      /^moz-extension:\/\//i, // Firefox extensions
      /^safari-extension:\/\//i, // Safari extensions

      //Ошибки Webpack (неважные во время разработки)
      /webpack\/hot-update.js/i, // Ошибки Hot Module Replacement (HMR)

      //Ошибки от сторонних сервисов (GTM, Ads, SDK)
      /googletagmanager\.com/i, // Google Tag Manager
      /adsbygoogle/i, // Google Ads
      /facebook\.com/i, // Facebook SDK
    ],

    ignoreErrors: [
      "Rendered fewer hooks than expected. This may be caused by an accidental early return statement.",

      // Ошибки авторизации (обычно не критичны, ожидаемы)
      "Request failed with status code 401", // Неавторизованный доступ
      "Request failed with status code 429", // Слишком много запросов (rate limit)

      // Ошибки пользователя (случайные ошибки, неправильные клики)
      "ResizeObserver loop limit exceeded", // Проблема с ресайзом элементов (не критично)

      // Ошибки браузеров (не зависят от кода, нельзя исправить)
      "ResizeObserver loop completed with undelivered notifications",
      "ResizeObserver loop limit exceeded",
      "SecurityError: The operation is insecure", // Политика безопасности браузера
      "NS_ERROR_NOT_AVAILABLE", // Ошибка Firefox
      "Blocked a frame with origin", // Проблема с iframes

      // Ошибки React (можно игнорировать, если используешь новый React)
      "ReactDOM.render is no longer supported",
      "Warning: Each child in a list should have a unique 'key' prop", // Неправильный `key` в списке
      "Cannot update a component while rendering a different component", // Ошибка обновления в рендере
      "Warning: componentWillReceiveProps has been renamed", // Устаревший метод React
    ],
  });
}

type Props = {
  children: React.ReactNode;
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
export const sentrySetUser = Sentry.setUser;
export const sentryWithProfiler = Sentry.withProfiler;

export const logSentryError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    // Это ошибка Axios
    Sentry.captureException(error, {
      extra: {
        message: error.message,
        url: error.config?.url,
        method: error.config?.method,
        requestData: error.config?.data,
        responseStatus: error.response?.status,
        responseData: error.response?.data,
      },
    });
  } else if (error instanceof Error) {
    // Обычная ошибка
    Sentry.captureException(error, {
      extra: { message: error.message, stack: error.stack },
    });
  } else {
    // Любые другие неизвестные ошибки
    Sentry.captureException(new Error(String(error)), {
      extra: { rawError: error },
    });
  }
};

export const SentryProvider = ({ children }: Props) => {
  return <>{children}</>;
};
