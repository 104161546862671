import { Group } from "@mantine/core";
import { motion } from "framer-motion";
import React from "react";

import { NewsFormType } from "@common/Types";
import { Typography } from "@components/Elements";

const APP_NEWS_COLORS = ["#2998FF", "#745ADC", "#3065ED", "#EF8E1C"];

interface Props {
  setFieldValue: (field: string, value: string) => void;
  values: NewsFormType;
  error?: string | undefined;
  touched?: boolean | undefined;
}

export const PickColor: React.FC<Props> = ({
  setFieldValue,
  values,
  error,
  touched,
}) => {
  const onClickHandler = (color: string) => {
    if (values.background_color === color) {
      setFieldValue("background_color", "");
    } else {
      setFieldValue("background_color", color);
    }
  };

  return (
    <motion.div
      initial={{ x: -80 }}
      animate={{ x: 0 }}
      transition={{ type: "spring", stiffness: 100 }}
      className="transition-all duration-75"
    >
      <Typography variant="body-1-b" color="neutral-800">
        Выберите цвет блока <span className="text-red-500">*</span>
      </Typography>
      <Group className="rounded-md px-3 py-4 bg-white border">
        {APP_NEWS_COLORS.map((color) => (
          <motion.button
            key={color}
            type="button"
            onClick={() => onClickHandler(color)}
            className="w-10 h-10 flex justify-center items-center rounded-md"
            style={{ backgroundColor: color }}
            whileHover={{
              scale: 1.2,
              transition: { duration: 0.3 },
            }}
            whileTap={{ scale: 0.9 }}
          >
            {values.background_color === color && (
              <svg
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <motion.path
                  d="M2 9.71875L7.47368 15L18 2"
                  stroke="#2C4D72"
                  strokeWidth="3"
                  strokeLinecap="round"
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                />
              </svg>
            )}
          </motion.button>
        ))}
      </Group>
      {error && touched ? (
        <p className="text-red-600 text-sm mt-1">Выберите один из цветов</p>
      ) : null}
    </motion.div>
  );
};
