import React from "react";

import { IS_MOTOR, IS_NAVI } from "@common/Constants";

type Props = {
  type: "driver" | "rider";
};

export const PlatformLogoIcon = ({ type }: Props): JSX.Element => {
  switch (type) {
    case "driver":
      {
        if (IS_NAVI) {
          return (
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2005_6354)">
                <rect width="28" height="28" fill="#0E2663" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.89526 12.3091C5.89526 7.17178 9.81526 3.8501 14.1479 3.8501C18.4805 3.8501 22.4005 7.17178 22.4005 12.3091C22.4005 15.7339 19.6462 19.788 14.1479 24.4817C8.64958 19.788 5.89526 15.7339 5.89526 12.3091ZM10.2179 10.7282H7.57964V13.3665H10.2171V16.004H12.8554V13.3657H10.2179V10.7282ZM10.2179 13.3657H10.2171V13.3665H10.2179V13.3657ZM15.4945 10.7282H12.8562V13.3665H15.4937V16.004H18.132V13.3657H15.4945V10.7282ZM15.4945 13.3657H15.4937V13.3665H15.4945V13.3657ZM20.7711 10.7282H18.1328V13.3665H20.7711V10.7282Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2005_6354">
                  <rect width="28" height="28" rx="6" fill="white" />
                </clipPath>
              </defs>
            </svg>
          );
        }
        if (IS_MOTOR) {
          return (
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="28" height="28" rx="14" fill="black" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.3717 18.6483C18.3717 18.6483 19.8506 16.341 19.8506 14.2483C19.8506 12.2817 18.6565 11.4312 17.94 11.3545C17.2955 11.2856 16.5453 11.3156 15.9534 11.8546C15.1623 12.5751 15.0083 14.1225 15.9534 14.2428C16.9463 14.3693 16.9624 13.2351 16.9624 13.2351C17.7473 12.6076 19.1075 14.7058 17.1072 15.9652C15.735 16.8292 14.5038 16.0606 13.9677 15.6791C13.4317 16.0606 12.2005 16.8292 10.8283 15.9652C8.82797 14.7058 10.1882 12.6076 10.9731 13.2351C10.9731 13.2351 10.9892 14.3693 11.9821 14.2429C12.9271 14.1225 12.7732 12.5751 11.9821 11.8546C11.3902 11.3156 10.64 11.2856 9.99543 11.3546C9.27896 11.4312 8.08486 12.2818 8.08486 14.2483C8.08486 16.341 9.56379 18.6483 9.56379 18.6483C9.56379 18.6483 7.77731 20.4483 6.57785 18.636C5.37839 16.8237 4.98367 12.8453 6.93016 10.7191C9.20058 8.23907 11.6973 9.32009 12.9271 9.95599C12.9867 9.07141 13.3055 8.62061 13.9677 8.62061C14.63 8.62061 14.9488 9.07139 15.0083 9.95598C16.2382 9.32008 18.7349 8.23905 21.0053 10.7191C22.9518 12.8452 22.5571 16.8237 21.3576 18.636C20.1582 20.4483 18.3717 18.6483 18.3717 18.6483Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.3717 18.6483C18.3717 18.6483 19.8506 16.341 19.8506 14.2483C19.8506 12.2817 18.6565 11.4312 17.94 11.3545C17.2955 11.2856 16.5453 11.3156 15.9534 11.8546C15.1623 12.5751 15.0083 14.1225 15.9534 14.2428C16.9463 14.3693 16.9624 13.2351 16.9624 13.2351C17.7473 12.6076 19.1075 14.7058 17.1072 15.9652C15.735 16.8292 14.5038 16.0606 13.9677 15.6791C13.4317 16.0606 12.2005 16.8292 10.8283 15.9652C8.82797 14.7058 10.1882 12.6076 10.9731 13.2351C10.9731 13.2351 10.9892 14.3693 11.9821 14.2429C12.9271 14.1225 12.7732 12.5751 11.9821 11.8546C11.3902 11.3156 10.64 11.2856 9.99543 11.3546C9.27896 11.4312 8.08486 12.2818 8.08486 14.2483C8.08486 16.341 9.56379 18.6483 9.56379 18.6483C9.56379 18.6483 7.77731 20.4483 6.57785 18.636C5.37839 16.8237 4.98367 12.8453 6.93016 10.7191C9.20058 8.23907 11.6973 9.32009 12.9271 9.95599C12.9867 9.07141 13.3055 8.62061 13.9677 8.62061C14.63 8.62061 14.9488 9.07139 15.0083 9.95598C16.2382 9.32008 18.7349 8.23905 21.0053 10.7191C22.9518 12.8452 22.5571 16.8237 21.3576 18.636C20.1582 20.4483 18.3717 18.6483 18.3717 18.6483Z"
                fill="url(#paint0_linear_98_17384)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_98_17384"
                  x1="13.9677"
                  y1="8.62061"
                  x2="13.9677"
                  y2="19.4447"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#73ECFC" />
                  <stop offset="1" stopColor="#11C7E0" />
                </linearGradient>
              </defs>
            </svg>
          );
        }
      }
      break;

    case "rider": {
      if (IS_NAVI) {
        return (
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2005_6364)">
              <rect width="28" height="28" fill="#3065ED" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.89478 12.309C5.89478 7.17178 9.81478 3.8501 14.1474 3.8501C18.48 3.8501 22.4 7.17178 22.4 12.309C22.4 15.7339 19.6457 19.788 14.1474 24.4817C8.64909 19.788 5.89478 15.7339 5.89478 12.309ZM10.2174 10.7292H7.57911V13.3675H10.2174V10.7292ZM10.2174 13.3657H12.8557V16.004H10.2174V13.3657ZM15.494 10.7292H12.8557V13.3675H15.4939V16.004H18.1322V13.3657H15.494V10.7292ZM15.494 13.3657H15.4939V13.3675H15.494V13.3657ZM20.7707 10.7292H18.1324V13.3675H20.7707V10.7292Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2005_6364">
                <rect width="28" height="28" rx="6" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      if (IS_MOTOR) {
        return (
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="28"
              height="28"
              rx="14"
              fill="url(#paint0_linear_98_17393)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.3717 18.6483C18.3717 18.6483 19.8506 16.341 19.8506 14.2483C19.8506 12.2817 18.6565 11.4312 17.94 11.3545C17.2955 11.2856 16.5453 11.3156 15.9534 11.8546C15.1623 12.5751 15.0083 14.1225 15.9534 14.2428C16.9463 14.3693 16.9624 13.2351 16.9624 13.2351C17.7473 12.6076 19.1075 14.7058 17.1072 15.9652C15.735 16.8292 14.5038 16.0606 13.9677 15.6791C13.4317 16.0606 12.2005 16.8292 10.8283 15.9652C8.82797 14.7058 10.1882 12.6076 10.9731 13.2351C10.9731 13.2351 10.9892 14.3693 11.9821 14.2429C12.9271 14.1225 12.7732 12.5751 11.9821 11.8546C11.3902 11.3156 10.64 11.2856 9.99543 11.3546C9.27896 11.4312 8.08486 12.2818 8.08486 14.2483C8.08486 16.341 9.56379 18.6483 9.56379 18.6483C9.56379 18.6483 7.77731 20.4483 6.57785 18.636C5.37839 16.8237 4.98367 12.8453 6.93016 10.7191C9.20058 8.23907 11.6973 9.32009 12.9271 9.95599C12.9867 9.07141 13.3055 8.62061 13.9677 8.62061C14.63 8.62061 14.9488 9.07139 15.0083 9.95598C16.2382 9.32008 18.7349 8.23905 21.0053 10.7191C22.9518 12.8452 22.5571 16.8237 21.3576 18.636C20.1582 20.4483 18.3717 18.6483 18.3717 18.6483Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.3717 18.6483C18.3717 18.6483 19.8506 16.341 19.8506 14.2483C19.8506 12.2817 18.6565 11.4312 17.94 11.3545C17.2955 11.2856 16.5453 11.3156 15.9534 11.8546C15.1623 12.5751 15.0083 14.1225 15.9534 14.2428C16.9463 14.3693 16.9624 13.2351 16.9624 13.2351C17.7473 12.6076 19.1075 14.7058 17.1072 15.9652C15.735 16.8292 14.5038 16.0606 13.9677 15.6791C13.4317 16.0606 12.2005 16.8292 10.8283 15.9652C8.82797 14.7058 10.1882 12.6076 10.9731 13.2351C10.9731 13.2351 10.9892 14.3693 11.9821 14.2429C12.9271 14.1225 12.7732 12.5751 11.9821 11.8546C11.3902 11.3156 10.64 11.2856 9.99543 11.3546C9.27896 11.4312 8.08486 12.2818 8.08486 14.2483C8.08486 16.341 9.56379 18.6483 9.56379 18.6483C9.56379 18.6483 7.77731 20.4483 6.57785 18.636C5.37839 16.8237 4.98367 12.8453 6.93016 10.7191C9.20058 8.23907 11.6973 9.32009 12.9271 9.95599C12.9867 9.07141 13.3055 8.62061 13.9677 8.62061C14.63 8.62061 14.9488 9.07139 15.0083 9.95598C16.2382 9.32008 18.7349 8.23905 21.0053 10.7191C22.9518 12.8452 22.5571 16.8237 21.3576 18.636C20.1582 20.4483 18.3717 18.6483 18.3717 18.6483Z"
              fill="black"
            />
            <defs>
              <linearGradient
                id="paint0_linear_98_17393"
                x1="14"
                y1="0"
                x2="14"
                y2="28"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#73ECFC" />
                <stop offset="1" stopColor="#11C7E0" />
              </linearGradient>
            </defs>
          </svg>
        );
      }
      break;
    }
    default:
      return (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="28" height="28" fill="#0E2663" />
        </svg>
      );
  }
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="28" fill="#0E2663" />
    </svg>
  );
};
