import { RichTextEditor, RichTextEditorProps } from "@mantine/rte";
import { Field, useField } from "formik";
import React, { FC } from "react";

import { Col, Typography } from "@components/Elements";

type TextEditorProps = RichTextEditorProps & {
  error?: string;
  label?: string;
  required?: boolean;
  value: string;
  setValue: (e: string) => void;
};

export const TextEditor: FC<TextEditorProps> = ({
  error,
  required,
  label,
  value,
  setValue,
  onFocus,
}) => {
  return (
    <Col className="w-full">
      {label && (
        <Typography
          as="label"
          color="neutral-800"
          variant="caption-b"
          className="font-medium mb-1"
        >
          {label}

          {required && <span className="text-error ml-1">*</span>}
        </Typography>
      )}
      <div className="mb-10 w-full">
        <div className="bg-gray-100 border-neutral-border-300">
          <RichTextEditor
            controls={[
              ["bold", "italic", "underline", "strike", "clean"],
              ["h1", "h2", "h3", "h4"],
              ["link"],
            ]}
            style={{ minHeight: 200 }}
            value={value}
            onChange={(text) => setValue(text)}
            onFocus={onFocus}
          />
        </div>
        {error ? <p className="text-red-600 text-sm">{error}</p> : null}
      </div>
    </Col>
  );
};

type FormTextEditorProps = {
  required?: boolean;
  label?: string;
  field: string;
};

export const FormTextEditor = ({ field, ...rest }: FormTextEditorProps) => {
  const [fieldProps, { touched, error }, helpers] = useField(field);

  
  return (
    <Field
      as={TextEditor}
      error={touched && error}
      {...fieldProps}
      {...rest}
      {...helpers}
    />
  );
};
