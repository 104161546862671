import React from "react";

import { strokeColorClasses } from "@common/Constants";
import { SVGProps } from "@common/Types";
import { cn } from "@common/Utils";

type Props = SVGProps & {
  variant?: "small" | "default";
};

export const TrashIcon = ({
  size = 24,
  strokeColor = "neutral-900",
  variant = "default",
  className,
  ...rest
}: Props) => {
  if (variant === "small") {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(strokeColorClasses[strokeColor], className)}
        {...rest}
      >
        <path
          d="M15 5V15.625C15 16.6608 14.1442 17.5 13.1092 17.5H6.85917C5.82333 17.5 5 16.6608 5 15.625V5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.25 5.00004H3.75"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33325 2.50004H11.6666"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6667 8.33337V14.1667"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33341 14.1667V8.33337"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(strokeColorClasses[strokeColor], className)}
      {...rest}
    >
      <path
        d="M4.16699 5.83366H15.8337"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 5.83301V14.9997C15 15.9205 14.2542 16.6663 13.3333 16.6663H6.66667C5.74583 16.6663 5 15.9205 5 14.9997V5.83301"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 3.12467H7.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33366 9.16699V13.3337"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 9.16699V13.3337"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
