import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { statisticsService } from "@api/services";
import { StatisticsPlatformActivityFiltersType } from "@common/Types";

type Params = StatisticsPlatformActivityFiltersType;

export const useGetPlatformActivityStatistics = (filters: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PLATFORM_ACTIVITY_STATISTICS, filters],
    queryFn: () => statisticsService.getPlatformActivityStatistics(filters),
  });
};
