import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { statisticsService } from "@api/services";
import { StatisticsConversionFiltersType } from "@common/Types";

type Params = StatisticsConversionFiltersType;

export const useGetRidesEventStatistics = (filters: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.RIDES_EVENT_STATISTICS, filters],
    queryFn: () => statisticsService.getRidesEventStatistics(filters),
  });
};
