import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { statisticsService } from "@api/services";
import { StatisticsRidesAllOrdersFiltersType } from "@common/Types";

type Params = StatisticsRidesAllOrdersFiltersType & {
  enabled?: boolean;
};

export const useGetRidesAllOrdersStatistics = ({
  enabled,
  ...filters
}: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.ALL_ORDERS_STATISTICS, filters],
    queryFn: () => statisticsService.getRidesAllOrdersStatistics(filters),
    enabled,
  });
};
