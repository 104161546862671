import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { statisticsService } from "@api/services";
import { StatisticsTariffsRevenueFiltersType } from "@common/Types";

type Params = StatisticsTariffsRevenueFiltersType;

export const useGetTariffsRevenueStatistics = (filters: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.TARIFFS_REVENUE_STATISTICS, filters],
    queryFn: () => statisticsService.getTariffsRevenueStatistics(filters),
  });
};
