import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { statisticsService } from "@api/services";
import { StatisticsUserGroupFiltersType } from "@common/Types";

type Params = StatisticsUserGroupFiltersType & { enabled?: boolean };

export const useGetUserGroupStatistics = ({ enabled, ...filters }: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.USER_GROUP_STATISTICS, filters],
    queryFn: () => statisticsService.getUserGroupStatistics(filters),
    enabled,
  });
};
