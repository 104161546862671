import { Modal as MantineModal } from "@mantine/core";
import React from "react";

import { ModalProps } from "./types";
import { VIEW_CLASSES } from "./variants";

export const Modal = ({
  children,
  view = "white",
  centered = true,
  ...props
}: ModalProps) => {
  return (
    <MantineModal
      classNames={VIEW_CLASSES[view]}
      centered={centered}
      {...props}
    >
      {children}
    </MantineModal>
  );
};
