import React, { useEffect, useState } from "react";
import ReactMapGL, {
  GeolocateControl,
  MapLayerMouseEvent,
  MapRef,
  NavigationControl,
  Popup,
} from "react-map-gl";

import {
  useGetCityConfigs,
  useGetDriverDetail,
  useGetZonePrice,
} from "@api/queries";
import {
  AdminCityInfo,
  CoordinatesType,
  IDType,
  InitialViewportType,
  MapDriver,
} from "@common/Types";
import { Col, LoadingIcon, Typography } from "@components/Elements";

import { Block } from "../block";
import { INITIAL_MAP_VIEWPORT } from "./common/constants";
import { buildZoneInitialValue } from "./common/utils/common";
import { DemandCard } from "./components/shared/demand-card";
import { MapLayer } from "./components/shared/layer";
import { MapCount } from "./components/shared/map-pin";
import { Pins } from "./components/shared/pins";
import { PopupCard } from "./components/shared/popup-card";

const initialMapViewport = {
  ...INITIAL_MAP_VIEWPORT,
  zoom: 6,
};

type Props = {
  title?: string;
  online?: MapDriver[];
  onride?: MapDriver[];
  city?: AdminCityInfo;
  enableSurgePricing?: boolean;
};

export const DashboardMap = ({
  title = "",
  city,
  online,
  onride,
  enableSurgePricing = true,
}: Props) => {
  const mapRef = React.useRef<MapRef>(null);

  const [showModal, setShowModal] = useState(false);
  const [popupInfo, setPopupInfo] = useState<MapDriver | null>(null);
  const [driverId, setDriverId] = useState<IDType>();
  const [demandPopupInfo, setDemandPopupInfo] =
    useState<CoordinatesType | null>(null);

  const {
    mutate: getPricesByZone,
    data: surgePrices,
    isPending: isLoadingSurgePrices,
    error,
  } = useGetZonePrice();

  const { originalData } = useGetDriverDetail({
    driverId: driverId,
    enabled: !!driverId,
  });

  const { data: cityConfigs } = useGetCityConfigs({
    cityId: (city?.id || "") as string,
    enabled: enableSurgePricing && !!city?.id,
  });

  useEffect(() => {
    if (city && city.centroid) {
      onSelectCity({
        longitude: city.centroid.coordinates[0],
        latitude: city.centroid.coordinates[1],
        zoom: 11,
      });
    }
  }, [city]);

  const onSelectCity = ({ longitude, latitude, zoom }: InitialViewportType) => {
    mapRef.current?.flyTo({ center: [longitude, latitude], zoom: zoom });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPopupInfo(null);
  };

  const handlerMarkerInfo = async (selectedDriver: MapDriver) => {
    setPopupInfo(selectedDriver);
    setShowModal(true);
    setDriverId(selectedDriver.driver_id);
  };

  const handlePopup = (event: MapLayerMouseEvent) => {
    if (isLoadingSurgePrices) return;

    const { lat, lng } = event.lngLat;

    setDemandPopupInfo({
      latitude: lat,
      longitude: lng,
    });

    getPricesByZone(
      {
        latitude: lat,
        longitude: lng,
      },
      { onSuccess: () => setShowModal(true) },
    );
  };

  const isBishkek = city?.name === "Бишкек";
  const isEnabled = isBishkek && cityConfigs?.surge_pricing_enabled;

  return (
    <Block title={title} titleVariant="body-1-b">
      <div className="pt-4 w-full relative border-box shadow-new h-[70vh] md:h-[700px]">
        <ReactMapGL
          ref={mapRef}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "0.375rem",
          }}
          initialViewState={buildZoneInitialValue(
            initialMapViewport,
            city?.centroid,
            12,
          )}
          mapStyle="mapbox://styles/mapbox/streets-v12"
          mapboxAccessToken="pk.eyJ1IjoienNlcmNxdyIsImEiOiJja3F3ZGFnbnkwa2poMzFxaGZ4bnBzeDE3In0.nlGFFWpud8L6b3hR8to8RQ"
          scrollZoom={false}
          onClick={handlePopup}
        >
          {!!(online && onride) && (
            <MapCount driversCount={online.length + onride.length} />
          )}

          {online?.map((driver) => (
            <Pins
              key={driver.driver_id}
              status={"online"}
              driver={driver}
              setDriverInfo={handlerMarkerInfo}
            />
          ))}

          {onride?.map((driver) => (
            <Pins
              key={driver.driver_id}
              status="onride"
              driver={driver}
              setDriverInfo={handlerMarkerInfo}
            />
          ))}

          {showModal && popupInfo && (
            <Popup
              anchor="left"
              offset={5}
              longitude={parseFloat(popupInfo.lon)}
              latitude={parseFloat(popupInfo.lat)}
              closeOnClick={false}
              onClose={handleCloseModal}
            >
              <PopupCard driver={originalData} />
            </Popup>
          )}

          {showModal && demandPopupInfo && !popupInfo && (
            <Popup
              anchor="left"
              longitude={demandPopupInfo.longitude}
              latitude={demandPopupInfo.latitude}
              closeOnClick={false}
              onClose={() => setShowModal(false)}
            >
              {isLoadingSurgePrices ? (
                <LoadingIcon />
              ) : (
                <DemandCard prices={surgePrices} />
              )}
              {error && (
                <Typography className="w-2/4">{error.message}</Typography>
              )}
            </Popup>
          )}

          {isEnabled && <MapLayer />}
          <GeolocateControl position="top-left" />
          <NavigationControl position="top-left" />
        </ReactMapGL>
      </div>
    </Block>
  );
};
