import { httpClient } from "@common/Libs/axios";
import {
  EventStatisticsType,
  QueryParams,
  RidesStatisticsFiltersType,
  StatisticRides,
  StatisticsConversionFiltersType,
  StatisticsFiltersType,
  StatisticsFinanceFiltersType,
  StatisticsFinanceType,
  StatisticsPlatformActivityFiltersType,
  StatisticsPlatformActivityType,
  StatisticsQueryParams,
  StatisticsRidesAllOrdersFiltersType,
  StatisticsRidesAllOrdersType,
  StatisticsTariffsRevenueFiltersType,
  StatisticsTariffsRevenueType,
  StatisticsUserGroupFiltersType,
  StatisticsUserGroupType,
  StatusStatisticsAverageResponse,
  StatusStatisticsTotalResponse,
} from "@common/Types";
import {
  commaSerializer,
  noneSerializer,
  removeEmptyFields,
} from "@common/Utils";

import { statisticsPath } from "./path";

/**
 * Описание методов statistics.
 */

export const statisticsService = {
  getRidesStatistics: (filters: RidesStatisticsFiltersType) => {
    return httpClient.get<StatisticRides>(statisticsPath.rides_statistics, {
      params: removeEmptyFields(filters),
      paramsSerializer: commaSerializer,
    });
  },

  getRidesStatisticsCancellation: (
    params: Pick<QueryParams<StatisticsFiltersType>, "filters">,
  ) => {
    const { filters, ...rest } = params;

    return httpClient.get<number[]>(
      statisticsPath.rider_statistics_cancellation,
      {
        params: removeEmptyFields({ ...filters, ...rest }),
        paramsSerializer: commaSerializer,
      },
    );
  },

  getRidesEventStatistics: (filters: StatisticsConversionFiltersType) => {
    return httpClient.get<EventStatisticsType[]>(
      statisticsPath.rider_event_statistics,
      {
        params: removeEmptyFields(filters),
        paramsSerializer: noneSerializer,
      },
    );
  },

  getDriversStatusStatisticsAverageAll: (
    filters: Pick<StatisticsQueryParams, "start_date" | "end_date">,
  ) => {
    return httpClient.get<StatusStatisticsTotalResponse>(
      statisticsPath.driver_status_statistics_average_all,
      {
        params: filters,
        paramsSerializer: noneSerializer,
      },
    );
  },

  getDriversShiftStatisticsAverageAll: (
    filters: Pick<StatisticsQueryParams, "start_date" | "end_date">,
  ) => {
    return httpClient.get<StatusStatisticsAverageResponse>(
      statisticsPath.driver_shift_statistics_average_all,
      {
        params: filters,
        paramsSerializer: noneSerializer,
      },
    );
  },

  getFinanceStatistics: (filters: StatisticsFinanceFiltersType) => {
    return httpClient.get<StatisticsFinanceType>(
      statisticsPath.finance_statistics,
      {
        params: removeEmptyFields(filters),
        paramsSerializer: noneSerializer,
      },
    );
  },

  getRidesAllOrdersStatistics: (
    filters: StatisticsRidesAllOrdersFiltersType,
  ) => {
    return httpClient.get<StatisticsRidesAllOrdersType>(
      statisticsPath.rides_all_orders_statistics,
      {
        params: removeEmptyFields(filters),
        paramsSerializer: noneSerializer,
      },
    );
  },

  getUserGroupStatistics: (filters: StatisticsUserGroupFiltersType) => {
    return httpClient.get<StatisticsUserGroupType>(
      statisticsPath.user_group_statistics,
      {
        params: removeEmptyFields(filters),
        paramsSerializer: noneSerializer,
      },
    );
  },

  getPlatformActivityStatistics: (
    filters: StatisticsPlatformActivityFiltersType,
  ) => {
    return httpClient.get<StatisticsPlatformActivityType>(
      statisticsPath.platform_activity_statistics,
      {
        params: removeEmptyFields(filters),
        paramsSerializer: noneSerializer,
      },
    );
  },

  getTariffsRevenueStatistics: (
    filters: StatisticsTariffsRevenueFiltersType,
  ) => {
    return httpClient.get<StatisticsTariffsRevenueType>(
      statisticsPath.tariffs_revenue_statistics,
      {
        params: removeEmptyFields(filters),
        paramsSerializer: noneSerializer,
      },
    );
  },
};
