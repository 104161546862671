import React from "react";

import { strokeColorClasses } from "@common/Constants";
import { SVGProps } from "@common/Types";
import { cn } from "@common/Utils";

type Props = SVGProps & {
  rotation: "top" | "right" | "bottom" | "left" | "leftTop" | "rightBottom";
};

const rotateIconClassnames = {
  top: "rotate-0",
  right: "rotate-90",
  bottom: "rotate-180",
  left: "-rotate-90",
  leftTop: "rotate-45",
  rightBottom: "rotate-[135deg]",
};

export const ArrowIcon = ({
  rotation = "top",
  strokeColor = "neutral-900",
  size = 24,
}: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(
        rotateIconClassnames[rotation],
        strokeColorClasses[strokeColor],
      )}
    >
      <path
        d="M12 5V19"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99902 10L12 4.99902L17.001 10"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
