import React, { ComponentProps, ReactNode } from "react";

import { backgroundColorClasses } from "@common/Constants";
import { cn } from "@common/Utils";
import { Typography, TypographyProps } from "@components/Elements";

import { CardProps } from "./types";

export const Card = ({
  children,
  bgColor = "neutral-0",
  className,
}: CardProps) => (
  <div
    className={cn(
      "flex flex-col p-2 shadow-new gap-3 rounded-[8px]",
      backgroundColorClasses[bgColor],
      className,
    )}
  >
    {children}
  </div>
);

Card.Title = ({
  children,
  variant = "body-2-b",
  color = "neutral-500",
  as = "label",
  className,
  ...rest
}: { children: ReactNode } & TypographyProps) => (
  <Typography
    variant={variant}
    color={color}
    as={as}
    className={cn(className)}
    {...rest}
  >
    {children}
  </Typography>
);

Card.Description = ({
  children,
  variant = "body-2-b",
  color = "neutral-800",
  className,
  ...rest
}: { children: ReactNode } & TypographyProps) => (
  <Typography
    variant={variant}
    color={color}
    className={cn(className)}
    {...rest}
  >
    {children}
  </Typography>
);

Card.Icon = ({ children, className }: ComponentProps<"div">) => (
  <div className={className}>{children}</div>
);
