import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { statisticsService } from "@api/services";
import { RidesStatisticsFiltersType } from "@common/Types";

type Params = RidesStatisticsFiltersType & {
  enabled?: boolean;
};

export const useGetRidesStatistics = ({
  enabled = true,
  ...filters
}: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.RIDES_STATISTICS, filters],
    queryFn: () => statisticsService.getRidesStatistics(filters),
    enabled,
  });
};
