import { TARGETS } from "../targets";

export const COLOR_CONFIGS = {
  [TARGETS.MOTOR]: {
    COLOR_BRAND_800: "#008295",
    COLOR_BRAND_700: "#119BAF",
    COLOR_BRAND_600: "#2BB5C8",
    COLOR_BRAND_500: "#44CEE2",
    COLOR_BRAND_100: "#CFF8FE",
  },
  [TARGETS.NAVI]: {
    COLOR_BRAND_800: "#173686",
    COLOR_BRAND_700: "#1F45A8",
    COLOR_BRAND_600: "#2855CB",
    COLOR_BRAND_500: "#3065ED",
    COLOR_BRAND_100: "#CEDBFB",
  },
};
