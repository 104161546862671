import React from "react";

import { Row } from "../flexbox";
import { Typography } from "../typography";

type Props = {
  title: string;
  count?: number;
  rightGroup?: React.ReactNode;
};

export const PageTitle = ({ title, count, rightGroup }: Props) => (
  <Row
    className="w-full flex-wrap gap-y-2"
    alignItems="items-center"
    justifyContent="justify-between"
  >
    <Typography
      as="h2"
      variant="heading-1-b"
      color="neutral-800"
      className="font-bold"
    >
      {title}
      <Typography
        as="span"
        color="neutral-500"
        variant="heading-2-b"
        className="font-semibold ml-2"
      >
        {count}
      </Typography>
    </Typography>

    <Row className="gap-4">{rightGroup}</Row>
  </Row>
);
