import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { newsService } from "@api/services";
import { IDType, NewsDetailType, NewsEnum } from "@common/Types";

type Params = {
  id: IDType;
  type: NewsEnum;
};

export const useGetNewsDetail = (params: Params) => {
  return useQuery<NewsDetailType>({
    queryKey: [QUERY_KEYS.NEWS_DETAILS, params],
    queryFn: async () => await newsService.getNewsDetail(params),
    enabled: !!params.id,
  });
};
