import { QueryClient } from "@tanstack/react-query";

const updateTime = 15 * 60 * 1000; // 15 минут в миллисекундах

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: updateTime,
      refetchOnMount: true,
      refetchOnWindowFocus: "always",
      refetchOnReconnect: "always",
      refetchInterval: updateTime,
      refetchIntervalInBackground: false,
    },
    mutations: {
      retry: 0,
    },
  },
});
