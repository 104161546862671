import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { photoControlService } from "@api/services";
import { queryClient } from "@common/Libs/react_query";
import { PhotoControlHandleStatusType } from "@common/Types";

export const useSetPhotoControlStatus = () => {
  return useMutation({
    mutationFn: (params: PhotoControlHandleStatusType) =>
      photoControlService.setPhotoControlStatus(params),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PHOTO_CONTROL_DETAILS],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.PHOTO_CONTROL],
      });

      toast.success(
        `Фото контроль ${
          (variables.status === "approved" && "одобрен") ||
          (variables.status === "rejected" && "отклонен")
        }`,
      );
    },
  });
};
