import { RadioGroupView, RadioGroupViewStyles } from "./types";

const font = {
  color: "text-neutral-text-800",
  size: "text-body-1-a",
};

const common = {
  focus: "focus:ring-brand-border-500",
  checked: "checked:bg-brand-bg-500 checked:border-brand-border-500",
};

export const RadioGroupClasses: Record<RadioGroupView, RadioGroupViewStyles> = {
  brand: {
    label: `${font.color} ${font.size}`,
    radio: `${common.focus} ${common.checked}`,
  },
};

const fontRadio = {
  color: "text-neutral-text-800",
  size: "text-caption-a",
};

export const RadioGroupRadioClasses: Record<
  RadioGroupView,
  RadioGroupViewStyles
> = {
  brand: {
    label: `${fontRadio.color} ${fontRadio.size}`,
    radio: `${common.focus} ${common.checked}`,
  },
};
