/**
 * Сервисы для news
 */

import { appConfig } from "@api/config";
import { IDType } from "@common/Types";

const src = appConfig.api.dashboard;

export const newsPath = {
  news: `${src}/news/`,
  newsDetail: (newsId?: IDType) => `${src}/news/${newsId}/`,
};
