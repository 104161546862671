import { FilterTabType, TransactionFilterType } from "@common/Types";
import { APP_FILTERS } from "@configs";

import { FRAUD_STATUSES, TRANSACTION_TYPES } from "./ENUMS";
import {
  driver_profile_balance_delete,
  driver_profile_balance_update,
  driver_profile_bonus_delete,
  driver_profile_bonus_update,
} from "./PAGES";

export type SelectOptionType = {
  label: string;
  value: string | number;
};

export const PAYMENT_FILTERS: FilterTabType[] = [
  { label: "Все", value: "all" },
  { label: "Наличные", value: "cash" },
  { label: "Корпоративный", value: "corporate" },
  { label: "Семейный", value: "family" },
  ...APP_FILTERS.PAYMENT_FILTERS,
];

export const CAR_COLORS: FilterTabType[] = [
  { value: "Красный", label: "Красный" },
  { value: "Бордовый", label: "Бордовый" },
  { value: "Синий", label: "Синий" },
  { value: "Голубой", label: "Голубой" },
  { value: "Зеленый", label: "Зеленый" },
  { value: "Коричневый", label: "Коричневый" },
  { value: "Бежевый", label: "Бежевый" },
  { value: "Розовый", label: "Розовый" },
  { value: "Фиолетовый", label: "Фиолетовый" },
  { value: "Серый", label: "Серый" },
  { value: "Серебристый", label: "Серебристый" },
  { value: "Желтый", label: "Желтый" },
  { value: "Оранжевый", label: "Оранжевый" },
  { value: "Золотистый", label: "Золотистый" },
  { value: "Черный", label: "Черный" },
  { value: "Белый", label: "Белый" },
];

export const STATUS: FilterTabType[] = [
  { label: "В работе", value: "ride_in_progress" },
  { label: "В поиске", value: "searching_for_driver" },
  { label: "Завершенные", value: "ride_completed" },
  { label: "Водитель не найден", value: "driver_not_found" },
  { label: "Отменен пассажиром", value: "rider_cancelled" },
  { label: "Отменен администратором", value: "admin_cancelled" },
  {
    label: "Отменен пассажиром во время поездки",
    value: "rider_cancelled_on_ride",
  },
];

export const RIDE_EVENT_STATISTICS = [
  {
    label: "Поездка запрошена",
    value: "ride_requested",
    color: "hsl(0, 70%, 40%)",
  },
  {
    label: "Поиск водителя",
    value: "searching_driver",
    color: "hsl(137.5, 71%, 41%)",
  },
  {
    label: "Водитель не найден",
    value: "driver_not_found",
    color: "hsl(275, 72%, 42%)",
  },
  {
    label: "Предложен заказ водителю",
    value: "offered_to_driver",
    color: "hsl(52.5, 73%, 43%)",
  },
  {
    label: "Водитель принял заказ",
    value: "driver_accepted",
    color: "hsl(190, 74%, 44%)",
  },
  {
    label: "Водитель взял заказ по завершению",
    value: "driver_accepted_as_by_finish",
    color: "hsl(327.5, 75%, 45%)",
  },
  {
    label: "Водитель пропустил заказ",
    value: "driver_missed",
    color: "hsl(105, 76%, 46%)",
  },
  {
    label: "Водитель отклонил заказ",
    value: "driver_declined",
    color: "hsl(242.5, 77%, 47%)",
  },
  {
    label: "Водитель ждет пассажира",
    value: "driver_expecting_rider",
    color: "hsl(20, 78%, 48%)",
  },
  {
    label: "Водитель отменил заказ",
    value: "driver_cancelled",
    color: "hsl(157.5, 79%, 49%)",
  },
  {
    label: "Пассажир отменил заказ",
    value: "rider_cancelled",
    color: "hsl(295, 80%, 50%)",
  },
  {
    label: "Отменен администратором",
    value: "admin_cancelled",
    color: "hsl(72.5, 81%, 51%)",
  },
  {
    label: "Администратор установил статус на месте",
    value: "admin_set_to_expecting_rider",
    color: "hsl(210, 82%, 52%)",
  },
  {
    label: "Администратор завершил поездку",
    value: "admin_completed",
    color: "hsl(347.5, 83%, 53%)",
  },
  {
    label: "Администратор завершил предыдущую поездку водителя",
    value: "admin_completed_driver_previous_ride",
    color: "hsl(125, 84%, 54%)",
  },
  { label: "От борта", value: "driver_pickup", color: "hsl(262.5, 85%, 55%)" },
  {
    label: "Поездка началась",
    value: "ride_started_for_rider",
    color: "hsl(40, 86%, 56%)",
  },
  {
    label: "Пассажир изменил направление",
    value: "rider_changed_destination",
    color: "hsl(177.5, 87%, 57%)",
  },
  {
    label: "Ожидание началось",
    value: "pre_ride_wait_started",
    color: "hsl(315, 88%, 58%)",
  },
  {
    label: "Ожидание закончилось",
    value: "pre_ride_wait_ended",
    color: "hsl(92.5, 89%, 59%)",
  },
  {
    label: "Платное ожидание началось",
    value: "wait_started",
    color: "hsl(230, 90%, 60%)",
  },
  {
    label: "Платное ожидание закончилось",
    value: "wait_ended",
    color: "hsl(7.5, 91%, 61%)",
  },
  {
    label: "Поездка завершена",
    value: "driver_completed",
    color: "hsl(145, 92%, 62%)",
  },
  {
    label: "Водитель завершил предыдущую поездку",
    value: "driver_completed_previous_ride",
    color: "hsl(282.5, 93%, 63%)",
  },
  {
    label: "Перерасчет стоимости поездки",
    value: "ride_price_recalculated",
    color: "hsl(60, 94%, 64%)",
  },
  {
    label: "Не обслуживается",
    value: "not_served",
    color: "hsl(197.5, 95%, 65%)",
  },
  {
    label: "Поездка не завершена",
    value: "not_completed",
    color: "hsl(335, 96%, 66%)",
  },
  {
    label: "Изменение способа оплаты",
    value: "payment_provider_changed",
    color: "hsl(112.5, 97%, 67%)",
  },
  {
    label: "Статус поездки успешно отправлен",
    value: "sse_status_success",
    color: "hsl(250, 98%, 68%)",
  },

  {
    label: "Ошибка отправки статуса поездки",
    value: "sse_status_fail",
    color: "hsl(27.5, 99%, 69%)",
  },
  {
    label: "Поездка успешно предложена SSE",
    value: "sse_offer_success",
    color: "hsl(165, 70%, 40%)",
  },
  {
    label: "Ошибка в предложении поездки SSE",
    value: "sse_offer_fail",
    color: "hsl(302.5, 71%, 41%)",
  },
  {
    label: "Заказ поступил",
    value: "ride_offer_received",
    color: "hsl(80, 72%, 42%)",
  },
  {
    label: "Заказ показан",
    value: "ride_offer_shown",
    color: "hsl(355, 74%, 44%)",
  },
  {
    label: "Поездка успешно предложена WS",
    value: "ws_offer_success",
    color: "hsl(355, 74%, 44%)",
  },
  {
    label: "Ошибка в предложении поездки WS",
    value: "ws_offer_fail",
    color: "hsl(132.5, 75%, 45%)",
  },

  { label: "Водитель найден", value: "driver_found", color: "" },
  { label: "Водитель получил заказ", value: "driver_received", color: "" },
] as const;

export const USER_ROLES: FilterTabType[] = [
  {
    value: "dispatcher",
    label: "Администратор-диспетчер",
  },
  { value: "citysuperadmin", label: "Администратор города" },
  { value: "parkadmin", label: "Администратор таксопарка" },
];

export const RIDE_EVENT_TYPE: FilterTabType[] = [
  { label: "Домой", value: "home" },
  { label: "По делам", value: "business" },
];

export const DRIVER_STATUS: FilterTabType[] = [
  { label: "На линии", value: "online" },
  { label: "Оффлайн", value: "offline" },
  { label: "Занят", value: "busy" },
  { label: "В пути", value: "on_ride" },
];

export const BALANCE: FilterTabType[] = [
  { label: "Списание", value: "withdraw" },
  { label: "Пополнение", value: "replenishment" },
];

export const DRIVERS_STATISTICS_STATUSES: FilterTabType[] = [
  { label: "Активные", value: "true" },
  { label: "Завершенные", value: "false" },
];

export const RIDERS_STATUS: FilterTabType[] = [
  { label: "Активные", value: "false" },
  { label: "Заблокированные", value: "true" },
];

export const AUDIENCE_FILTERS: FilterTabType[] = [
  { label: "Водители", value: "driver" },
  { label: "Пассажиры", value: "rider" },
];

export const DRIVER_APPLICATION_FILTERS: FilterTabType[] = [
  {
    label: "Новые",
    value: "new",
    accessKey: "driver_registration_new_application",
  },
  {
    label: "Отклоненные",
    value: "rejected",
    accessKey: "driver_registration_declined_application",
  },
  {
    label: "Одобренные",
    value: "approved",
    accessKey: "driver_registration_approved_application",
  },
];

export const DRIVER_APPLICATION_CAR_CHANGE_FILTERS: FilterTabType[] = [
  {
    label: "Новые",
    value: "new",
    accessKey: "driver_auto_new_application",
  },
  {
    label: "Отклоненные",
    value: "rejected",
    accessKey: "driver_auto_declined_application",
  },
  {
    label: "Одобренные",
    value: "approved",
    accessKey: "driver_auto_approved_application",
  },
];

export const FRAUD_CONTROL_FILTERS: FilterTabType[] = [
  { label: "На рассмотрении", value: FRAUD_STATUSES.IN_MODERATION },
  { label: "Одобренные", value: FRAUD_STATUSES.UNLOCKED },
  { label: "Отклоненные", value: FRAUD_STATUSES.BLOCKED },
];

export const NAVI_RIDES_FILTERS = [
  { label: "Все", value: "" },
  { label: "Navi", value: "false" },
  { label: "Jorgo", value: "true" },
];

export const DRIVER_CAR_CLASS: FilterTabType[] = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
];

export const DRIVER_STATUSES_FILTER: FilterTabType[] = [
  { label: "Использование функции", value: "in_progress" },
  { label: "Завершение функции", value: "finished" },
  { label: "Поездка не найдена", value: "not_found" },
  { label: "Новая", value: "new" },
  { label: "Поиск водителя", value: "searching_for_driver" },
  { label: "Водитель найден", value: "driver_found" },
  { label: "Предложен водителю", value: "offered_ride_to_driver" },
  { label: "Водитель не найден", value: "driver_not_found" },
  { label: "Водитель подъезжает", value: "driver_coming" },
  { label: "Ожидается пассажир", value: "expecting_rider" },
  { label: "Отменен пассажиром", value: "rider_cancelled" },
  { label: "Поездка в процессе", value: "ride_in_progress" },
  { label: "Поездка на ожидании", value: "ride_on_hold" },
  { label: "Поездка завершена", value: "ride_completed" },
];

export const TRANSACTION_HISTORY_STATUSES: FilterTabType[] = [
  { value: "block", label: "Деактивация" },
  { value: "unblock", label: "Активация" },
  { value: "refund", label: "Возврат" },
  { value: "debt", label: "Долг" },
  { value: "bonuses", label: "Бонусы" },
];

export const ACTIVATION_STATUSES: FilterTabType[] = [
  { value: "activation", label: "Активный" },
  { value: "deactivation", label: "Заблокированный" },
];

export const TRANSACTION_TYPE: FilterTabType[] = [
  { label: "Списание с электронного кошелька", value: "balance_withdraw" },
  { label: "Пополнение электронного кошелька", value: "balance_add" },
  { label: "Списание бонусов", value: "bonus_withdraw" },
  { label: "Пополнение бонусов", value: "bonuses_add" },
  { label: "Списание со счета", value: "bank_account_withdraw" },
  { label: "Пополнение счета", value: "bank_account_add" },
];

export const DRIVER_BALANCE_OPTIONS: FilterTabType[] = [
  {
    label: "Пополнение",
    value: TRANSACTION_TYPES.REPLENISHMENT,
    accessKey: driver_profile_balance_update,
  },
  {
    label: "Списание",
    value: TRANSACTION_TYPES.WITHDRAW,
    accessKey: driver_profile_balance_delete,
  },
];

export const DRIVER_BONUS_OPTIONS: FilterTabType[] = [
  {
    label: "Пополнение",
    value: TRANSACTION_TYPES.REPLENISHMENT,
    accessKey: driver_profile_bonus_update,
  },
  {
    label: "Списание",
    value: TRANSACTION_TYPES.WITHDRAW,
    accessKey: driver_profile_bonus_delete,
  },
];

export const financialReportsOptions = [
  {
    value: "corporate_transactions",
    label: "Реестр по поездкам",
  },
];

// TRANSACTION FILTER
export const TRANSACTION_TYPES_FILTER = [
  {
    value: TransactionFilterType.SYSTEM_COMMISSION,
    label: "Комиссия агрегатора",
    group: "Списания",
  },
  {
    value: TransactionFilterType.PARK_COMMISSION,
    label: "Комиссия таксопарка",
    group: "Списания",
  },
  {
    value: TransactionFilterType.RIDE_PAYMENT,
    label: "Пополнение за поездку",
    group: "Пополнение",
  },
  {
    value: TransactionFilterType.BANK_COMMISSION,
    label: "Комиссия банка",
    group: "Списания",
  },
  {
    value: TransactionFilterType.DEBT_PAYMENT,
    label: "Пополнение (Оплата долга)",
    group: "Пополнение",
  },

  {
    value: TransactionFilterType.ADMIN_TRANSACTION,
    label: "Пополнение админом",
    group: "Пополнение",
  },
  ...APP_FILTERS.TRANSACTION_TYPES,
];
