import { useMutation } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { newsService } from "@api/services";
import { queryClient } from "@common/Libs/react_query";
import { NewsEditFormType } from "@common/Types";

export const useEditNews = () => {
  return useMutation({
    mutationFn: (params: NewsEditFormType) => newsService.editNews(params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.NEWS],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.NEWS_DETAILS],
      });
    },
  });
};
