import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { statisticsService } from "@api/services";
import { StatisticsFinanceFiltersType } from "@common/Types";

type Params = StatisticsFinanceFiltersType;

export const useGetFinanceStatistics = (filters: Params) => {
  return useQuery({
    queryKey: [QUERY_KEYS.FINANCE_STATISTICS, filters],
    queryFn: () => statisticsService.getFinanceStatistics(filters),
  });
};
