import React, { lazy, Suspense } from "react";

import { useModal } from "@common/Providers";
import { ModalMapMetaKeys } from "@common/Types";
import ErrorBoundary from "@components/Shared/error-boundary";

type ModalLazyComponentProps = {
  filename: ModalMapMetaKeys;
};

export function ModalLazyComponent({ filename }: ModalLazyComponentProps) {
  const { isModalOpen, closeModal, getModalMeta } = useModal();

  const Component = lazy(() => import(`./${filename}/index.tsx`));

  const isOpen = isModalOpen(filename);
  const meta = getModalMeta(filename);

  return (
    <Suspense fallback={null}>
      <ErrorBoundary>
        {filename ? (
          <Component
            opened={isOpen}
            onClose={() => closeModal(filename)}
            {...meta}
          />
        ) : null}
      </ErrorBoundary>
    </Suspense>
  );
}
