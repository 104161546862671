import {
  endOfDay,
  endOfToday,
  endOfYesterday,
  format,
  formatISO,
  isDate,
  isSameDay,
  parse,
  startOfDay,
  startOfToday,
  startOfYesterday,
  subDays,
} from "date-fns";
import dayjs from "dayjs";
import { chain, isArray, isEmpty, omit, pick } from "radash";
import { AnyObject } from "yup";

import { formatOfDate } from "@common/Constants";
import { DateRageFilterType } from "@common/Types";
import { formatDate, removeEmptyFilterValues } from "@common/Utils";

export const getDateString = (date = "", dateFormat = "yyyy-MM-dd") => {
  return format(new Date(date), dateFormat);
};

export const getToday = (dateFormat?: string): DateRageFilterType => {
  return [
    formatDate(startOfToday(), dateFormat ? dateFormat : formatOfDate),
    formatDate(endOfToday(), dateFormat ? dateFormat : formatOfDate),
  ];
};

export const getYesterday = (dateFormat?: string): DateRageFilterType => {
  return [
    formatDate(startOfYesterday(), dateFormat ? dateFormat : formatOfDate),
    formatDate(endOfYesterday(), dateFormat ? dateFormat : formatOfDate),
  ];
};

export const getWeek = (dateFormat?: string): DateRageFilterType => {
  return [
    formatDate(subDays(new Date(), 7), dateFormat ? dateFormat : formatOfDate),
    formatDate(new Date(), dateFormat ? dateFormat : formatOfDate),
  ];
};

export const getMonth = (dateFormat?: string): DateRageFilterType => {
  const today = new Date();
  const startDate = subDays(today, 30);

  return [
    format(startDate, dateFormat ? dateFormat : formatOfDate),
    format(today, dateFormat ? dateFormat : formatOfDate),
  ];
};

export const getAll = (): DateRageFilterType => {
  return [null, null];
};

export const formatDateRange = (
  range: string,
  dateFormat?: string,
): DateRageFilterType => {
  switch (range) {
    case "today":
      return getToday(dateFormat);
    case "yesterday":
      return getYesterday(dateFormat);
    case "week":
      return getWeek(dateFormat);
    case "month":
      return getMonth(dateFormat);
    case "all":
      return getAll();
    default:
      return getAll();
  }
};

export function formatTime(
  seconds: number | null,
  format: Array<"hours" | "minutes" | "seconds"> = [
    "hours",
    "minutes",
    "seconds",
  ],
) {
  if (seconds !== 0 && !seconds) return "";

  const time = {
    hours: Math.floor(seconds / 3600),
    minutes: Math.floor(seconds / 60),
    seconds: seconds % 60,
  };

  const result = format
    ?.map((item) => String(time[item]).padStart(2, "0"))
    .join(":");

  return result;
}

export const isDateObjectRange = (
  dates: [Date | null, Date | null],
): dates is [Date, Date] => {
  return isArray(dates) && isDate(dates[0]) && isDate(dates[1]);
};

export const buildDateRangeFromTextRange =
  (key: string) => (filters: AnyObject) => {
    if (isEmpty(filters)) return filters;
    if (!filters[key]) return filters;

    if (filters[key] === "custom" || filters[key] === "all") {
      return {
        ...omit(filters, [key]),
      };
    }

    return {
      ...omit(filters, [key]),
      date_range: formatDateRange(filters[key] as string),
    };
  };

export const buildDateRangeFiltersValues = <T>(filters: T): Partial<T> => {
  const chained = chain(
    removeEmptyFilterValues,
    buildStartEndDateFromDateRange("date_range"),
  );
  return chained(filters);
};

export const buildStartEndDateFromDateRange =
  (key: string) => (filters: AnyObject) => {
    if (!filters) return filters;

    if (filters[key] && isArray(filters[key])) {
      const [start, end] = filters[key] as [
        string | undefined | null,
        string | undefined | null,
      ];

      return {
        ...omit(filters, [key]),
        ...(start
          ? {
              start: startOfDay(
                parse(start, "dd-MM-yyyy", new Date()),
              ).toISOString(),
            }
          : {}),
        ...(end
          ? {
              end: new Date(
                endOfDay(parse(end, "dd-MM-yyyy", new Date())).getTime() + 1,
              ).toISOString(),
            }
          : {}),
      };
    }

    return filters;
  };

export const isDayNotMoreOrLessThenSelected =
  (selectedDay: Date, allowedOffset: number) =>
  (dateForCheck: Date): boolean => {
    const selectedDate = dayjs(selectedDay);
    const dateToCheck = dayjs(dateForCheck);
    const differenceInDays = selectedDate.diff(dateToCheck, "day");
    return (
      differenceInDays > allowedOffset || differenceInDays < -allowedOffset
    );
  };

export const isDateStringRange = (
  dates: any | any[],
): dates is [string, string] => {
  return (
    isArray(dates) &&
    dates[0] &&
    dates[1] &&
    isDate(parse(dates[0], formatOfDate, new Date())) &&
    isDate(parse(dates[1], formatOfDate, new Date()))
  );
};

export const takeOnlyNeedFilters = (keys: string[]) => (filters: AnyObject) => {
  if (!filters) return filters;
  return pick(filters, keys);
};

export const buildCommonDateFilters = <T>(
  filters: T,
  neededFilters: string[],
): T => {
  const chained = chain(
    takeOnlyNeedFilters(neededFilters),
    removeEmptyFilterValues,
    buildDateRangeFromTextRange("select_range"),
  );
  return chained(filters);
};

export const getDateFromSeconds = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const baseDate = new Date(0);
  baseDate.setHours(hours, minutes, seconds, 0);

  return baseDate;
};

export const getSecondsFromDate = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  let totalSeconds = 0;

  if (hours > 0) totalSeconds += hours * 3600;
  if (minutes > 0) totalSeconds += minutes * 60;
  if (seconds > 0) totalSeconds += seconds;

  return totalSeconds;
};

export const getDateRangeReport = (date_range?: (string | null)[]) => {
  if (!date_range) return "";

  const hasDates = date_range.every((date) => date);

  const startDay = date_range[0]?.replaceAll("-", ".");
  const endDay = date_range[1]?.replaceAll("-", ".");

  const dateRangeReport = `Отчет за период: ${startDay} - ${endDay}`;

  if (hasDates) return dateRangeReport;

  return "Данные показаны за все время";
};
