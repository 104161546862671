import { useFormikContext } from "formik";
import React from "react";

import { useGetTariffs, useGetTariffsById } from "@api/queries";
import { useDelay, useDimension } from "@common/Hooks";
import { IDType } from "@common/Types";

import { TariffIcon } from "../assets/icons";
import { FilterValueType } from "../common/types";
import { buildTariffsFilter, updateChips } from "../common/utils";
import { CustomSelect } from "./custom-select";
import { MultiSelectFilter } from "./multi-select-filter";

type Props<T> = {
  field: keyof T;
  selectedCity?: IDType;
  clearable?: boolean;
  singleChoice?: boolean;
};

export const TariffsFilter = <T,>({
  field,
  selectedCity,
  clearable = true,
  singleChoice = true,
  ...props
}: Props<T>) => {
  const { isDesktop } = useDimension();

  const { values, setValues, submitForm } =
    useFormikContext<FilterValueType<T>>();

  const debounceSubmitForm = useDelay(submitForm, 300);

  const { data: tariffs = [] } = useGetTariffs({ enabled: !selectedCity });
  const { data: tariffsByCity = [] } = useGetTariffsById({
    city_id: selectedCity as number,
    enabled: !!selectedCity,
  });

  const options = buildTariffsFilter(selectedCity ? tariffsByCity : tariffs);

  const handleSelect = (selectedValue: string) => {
    const fullValue = options.find((option) => option.value === selectedValue);
    const label = fullValue?.label || "";
    const chips = values.chips || [];

    const updatedChips = updateChips(chips, field as string, label);

    setValues({ ...values, [field]: selectedValue, chips: updatedChips });

    if (isDesktop) {
      debounceSubmitForm();
    }
  };

  if (!singleChoice) {
    return (
      <MultiSelectFilter
        field={field}
        options={options}
        label="Тариф"
        placeholder="Тариф"
        icon={TariffIcon}
      />
    );
  }

  const value = values[field] ? (values[field] as unknown as string) : null;
  return (
    <CustomSelect
      clearable={clearable}
      value={value}
      label={isDesktop ? "" : "Тариф"}
      data={options}
      icon={<TariffIcon />}
      placeholder="Тариф"
      onChange={handleSelect}
      {...props}
    />
  );
};
